import React, { FunctionComponent } from "react"
import { Post } from "../../models/post"
import { Grid } from "../common/common"
import { Card } from "../card/card"
import { getImage } from "gatsby-plugin-image"

interface PostGridProps {
  posts: Post[]
}

const PostGrid: FunctionComponent<PostGridProps> = ({ posts }) => (
  <Grid>
    {posts.map((post, index) => (
      <Card
        title={post.frontmatter.title}
        path={post.frontmatter.path}
        featuredImage={getImage(post.frontmatter.featuredImage)}
        featuredImageAlt={post.frontmatter.featuredImageAlt}
        content={post.frontmatter.description}
        key={index}
        meta={{
          time: post.frontmatter.createdDate,
          timePretty: post.frontmatter.createdDatePretty,
          category:
            post.frontmatter.categories.length > 0
              ? post.frontmatter.categories[0]
              : null,
        }}
      />
    ))}
  </Grid>
)

export default PostGrid
