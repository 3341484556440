import React, { FunctionComponent } from "react"
import { Container } from "../common/common"
import * as S from "./subheader.styled"

export interface SubheaderProps {
  title: string
  subtitle?: string
  backgroundColor?: string
  textColor?: string
}

const Subheader: FunctionComponent<SubheaderProps> = ({
  title,
  subtitle,
  backgroundColor,
  textColor,
}) => (
  <S.StyledSubheader backgroundColor={backgroundColor} textColor={textColor}>
    <Container>
      <S.SubheaderTitle>
        {title}
        <S.SubheaderSubtitle>{subtitle}</S.SubheaderSubtitle>
      </S.SubheaderTitle>
    </Container>
  </S.StyledSubheader>
)

export default Subheader
