import React, { FunctionComponent } from "react"
import Layout from "../../components/layout/layout"
import { graphql } from "gatsby"
import { Post } from "../../models/post"
import { Category } from "../../models/category"
import Subheader from "../../components/subheader/subheader"
import SEO from "../../components/seo/seo"
import Theme from "../../styles/theme"
import PostGrid from "../../components/post-grid/post-grid"

interface CategoryTemplateProps {
  data: {
    category: Category
    posts: {
      edges: Array<{ node: Post }>
    }
  }
  location: Location
}

const CategoryTemplate: FunctionComponent<CategoryTemplateProps> = ({
  data,
  location,
}) => {
  let category = data.category

  const posts = data.posts.edges.map(node => node.node)

  if (!category && posts.length > 0) {
    category = {
      name: posts[0].frontmatter.categories[0],
      color: Theme.layout.primaryColor,
      icon: null,
      featured: false,
    }
  }

  return (
    <Layout bigHeader={false}>
      <SEO title={category.name} location={location} type={`Series`} />
      <Subheader
        title={category.name}
        subtitle={`${posts.length} posts`}
        backgroundColor={category.color}
        textColor={category.textColor}
      />
      <PostGrid posts={posts} />
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query ($category: String!) {
    category: categories(name: { eq: $category }) {
      name
      color
      textColor
    }
    posts: allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: $category } } }
      sort: { fields: frontmatter___createdDate, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            categories
            description
            createdDate
            createdPretty: createdDate(
              formatString: "DD MMMM, YYYY"
              locale: "pt-br"
            )
            featuredImageAlt
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [WEBP]
                  quality: 60
                )
              }
            }
          }
        }
      }
    }
  }
`
